import { Component, Input } from '@angular/core';

@Component({
  selector: 'ig-loading-block',
  templateUrl: './loading.block.component.html',
  styleUrls: ['./loading.block.component.scss'],
})
export class LoadingBlockComponent {
  @Input() rows: number = 2;
  @Input() columns: number = 5;

  toArray(count: number) {
    return Array.from(Array(count).keys());
  }
}
