import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';

import './app/common/utils/extensions';

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => {
    console.error(err);
    console.error('err.toString()', err.toString());

    if (err.toString().includes('NG0908')) {
      window.location.reload();
    }
  });
