import {
  Component,
  HostBinding,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable, delay, takeUntil } from 'rxjs';
import { BaseComponent } from 'src/app/common/components/base.component';
import { CustomDefaultPlaceCategory } from 'src/app/modules/shared/models/custom.default.place.category';
import { PlaceType } from 'src/app/modules/shared/models/place.type';
import { frontendSettingsSelector } from 'src/app/modules/shared/ngrx.stores/frontend.settings/selectors';
import {
  IAppState,
  IFrontendSettings,
} from 'src/app/modules/shared/ngrx.stores/frontend.settings/states';
import * as PlaceQueryCommandActions from 'src/app/modules/place-calendar/ngrx.stores/place.query.command/actions';
import { placeQueryCommandSelector } from '../../ngrx.stores/place.query.command/selectors';
import { CalendarQueryCommand } from 'src/app/modules/shared/models/calendar.action.command';
import { VisitorPlaceService } from 'src/app/modules/event-calendar/services/visitor.place.service';
import { PlaceStore } from '../../ngrx.stores/place.query.command/stores';
import { Location } from '@angular/common';
import { VisitorService } from '../../../shared/services/visitor.service';

@Component({
  selector: 'ig-custom-default-place-category-filter',
  templateUrl: './custom.default.place.category.filter.component.html',
  styleUrls: ['./custom.default.place.category.filter.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomDefaultPlaceCategoryFilterComponent extends BaseComponent {
  @HostBinding('class') hostClass = 'ig-custom-default-category';
  customDefaultPlaceCategory: CustomDefaultPlaceCategory | null = null;
  @Input() mode: 'expand' | 'dropdown' = 'dropdown';
  frontendSettings$: Observable<IFrontendSettings | null>;
  settings: IFrontendSettings | null = null;
  title = '';

  @Input() isOnlyShowNodeFromUrl: boolean = false; // for nearby places component, it will show all categories in the dropdown, so this value should be false
  @Input() isTriggerCalendarReload: boolean = true; // on topbar/sidebar, it needs to reload the calendar. But on nearby place component, it will not reload the calendar, only reload nearby places. In this case it will not trigger NgRx dispatch
  node: PlaceType | null = null; // specified category node from URL. If has this value, only render the category and sub-categories of this node in the dropdown

  isLoading: boolean = false;

  constructor(
    public activatedRoute: ActivatedRoute,
    public placeService: VisitorPlaceService,
    public visitorService: VisitorService,
    private _router: Router,
    private _location: Location,
    private _store: Store<IAppState>
  ) {
    super({activatedRoute});
    this._store
      .pipe(select(frontendSettingsSelector))
      .subscribe((settings: IFrontendSettings) => {
        if (!settings) return;
        this.settings = settings;
      });

    // set title and selected outline
    this._store
      .pipe(takeUntil(this.isDestroyed), select(placeQueryCommandSelector))
      .subscribe((cmd: CalendarQueryCommand) => {
        // receive NgRx store to update the category dropdown on topbar/side. Nearby places component doesn't need this
        if (!cmd || !this.isTriggerCalendarReload) return;
        this.title = cmd.category?.toLowerCase() !== 'all' ? cmd.category : '';
        if (this.isOnlyShowNodeFromUrl) {
          this.node = cmd.categoryNode || this.node;
          console.log('node --------', this.node);
        }
        // this.isLoading = false;
      });

    // have no idea why the custom default place category dispatch doesn't work in event details page's nearby places component
    // here is to subscribe to the custom default category changes and set title accordingly
    PlaceStore.customDefaultCategoryStore
      .pipe(delay(100)) // delay to move this behind NgRx subscribe
      .subscribe((store) => {
        if (store && !this.isTriggerCalendarReload) {
          this.title =
            store.categoryName?.toLowerCase() !== 'all'
              ? store.categoryName
              : '';
          if (this.isOnlyShowNodeFromUrl) {
            this.node = store.categoryNode || this.node;
          }
        }
      });
  }

  public get isShowThingsToDo() {
    return (
      this.settings &&
      (this.settings.toggle.placeCategory.activity ||
        this.settings.toggle.placeCategory.art ||
        this.settings.toggle.placeCategory.outdoor ||
        this.settings.toggle.placeCategory.entertainment)
    );
  }

  public isSelected(title: string) {
    return false;
  }

  onSelectCustomDefaultPlaceCategory(node: PlaceType | null) {
    console.log('node', node);
    console.log('this.node', this.node);
    if (!node) {
      if (!this.node) {
        // ALL
        this.title = '';
        this.isTriggerCalendarReload &&
        this._store.dispatch(
          PlaceQueryCommandActions.setCumtomDefaultCategoryList({
            categoryList: undefined,
          })
        );

        // have no idea why the above dispatch doesn't work in event details page's nearby places component
        // here we notify the nearby places component to load the places
        PlaceStore.customDefaultCategoryStore.next({
          categoryList: undefined,
        });

        const currentPath = this._location.path();
        if (currentPath.includes('ig-custom-places=')) {
          let originUrl = currentPath.split('ig-custom-places=')[0];
          originUrl = (originUrl.endsWith('?') || originUrl.endsWith('&')) ? originUrl.substring(0, originUrl.length - 1) : originUrl;
          this._location.go(originUrl);
        }
      } else {
        // return to the specified node in URL (only render this node in the dropdown)
        let categoryList = [];
        this.placeService.getAllSubCategories(this.node, categoryList);
        this.isTriggerCalendarReload &&
        this._store.dispatch(
          PlaceQueryCommandActions.setCumtomDefaultCategoryList({
            categoryList: categoryList,
            categoryName: this.node.title,
            categoryNode: this.node,
          })
        );
      }
    } else {
      let categoryList = [];
      this.placeService.getAllSubCategories(node, categoryList);
      this.isTriggerCalendarReload &&
      this._store.dispatch(
        PlaceQueryCommandActions.setCumtomDefaultCategoryList({
          categoryList: categoryList,
          categoryName: node.title,
        })
      );

      // have no idea why the above dispatch doesn't work in event details page's nearby places component
      // here we notify the nearby places component to load the places
      PlaceStore.customDefaultCategoryStore.next({
        categoryList: categoryList,
        categoryName: node.title,
      });
    }

    if (this.hubName == 'Savannah' || this.hubName == 'test01') {
      if (node || this.node) {
        const currentPath = this._location.path();
        if (currentPath.includes('ig-custom-places=')) {
          this._location.go(currentPath.split('ig-custom-places=')[0] + `ig-custom-places=${this.visitorService.convertToUrlSafeString((node || this.node).title)}-${(node || this.node)._id}`);
          return;
        }
        const connector = currentPath.includes('?') ? '&' : '?';
        this._location.go(currentPath + `${connector}ig-custom-places=${this.visitorService.convertToUrlSafeString((node || this.node).title)}-${(node || this.node)._id}`);
      }
    }
  }

  filterPlace(category: string) {
    this.title = category.toLowerCase() === 'all' ? '' : category;
    this.isTriggerCalendarReload &&
    this._store.dispatch(
      PlaceQueryCommandActions.setDefaultCategory({
        category,
        categoryName: category,
      })
    );

    // have no idea why the above dispatch doesn't work in event details page's nearby places component
    // here we notify the nearby places component to load the places
    PlaceStore.customDefaultCategoryStore.next({
      category,
      categoryName: category,
    });
  }
}
