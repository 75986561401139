import {
  Directive,
  ElementRef,
  HostListener,
  Output,
  EventEmitter,
} from '@angular/core';

@Directive({
  selector: '[h-scroll]',
})
export class HorizontalScrollDirectiveDirective {
  @Output() onScroll: EventEmitter<number> = new EventEmitter();

  constructor(private ele: ElementRef) {}

  @HostListener('wheel', ['$event'])
  public scroll(event: any): void {
    const target = this.ele.nativeElement;
    const toLeft = event['deltaY'] < 0 && target.scrollLeft > 0;
    const toRight =
      event['deltaY'] > 0 &&
      target.scrollLeft < target.scrollWidth - target.clientWidth;

    if (toLeft || toRight) {
      event.preventDefault();
      target.scrollLeft += event['deltaY'];
      this.onScroll.emit(target.scrollLeft);
    }
  }
}
