import { Injectable } from '@angular/core';
import { config } from 'src/app/config/config';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private static _config = config;
  constructor() {}

  public static get config() {
    return ConfigService._config;
  }

  public static set config(value) {
    this._config = value;
  }
}
