import { Component, Input } from '@angular/core';

@Component({
  selector: 'ig-success-checkmark',
  templateUrl: './success.checkmark.component.html',
  styleUrls: ['./success.checkmark.component.scss'],
})
export class SuccessCheckmarkComponent {
  @Input() width: number = 50;
}
