import { ErrorHandler, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../environments/environment';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  private apiAddress = environment.apiAddress;
  private headers = new HttpHeaders();

  constructor(public httpClient: HttpClient) {
  }

  handleError(error: any): void {
    const chunkFailedMessage = /ChunkLoadError/; // /Loading chunk [\d]+ failed/;
    const chunkFailedMessage1 = /Loading chunk/;

    console.warn('GlobalErrorHandler', error);

    // sometimes ImGoing chunks loading fails, so we need to reload the page
    // https://itidigital.atlassian.net/jira/software/c/projects/DXP/boards/13?selectedIssue=DXP-1346
    if (
      chunkFailedMessage.test(error.message) ||
      chunkFailedMessage1.test(error.message)
    ) {
      try {
        const initUrl = localStorage.getItem('INITIAL_URL');
        const body = {
          hubName: 'ImGoing',
          source: `web-v3 (${window.location.href})`,
          level: 'error',
          message: error.message,
          note: `initUrl: ${initUrl}`,
          exception: error.toString(),
        };

        this.httpClient.post(`${this.apiAddress}common/log`, body, {
          headers: this.headers,
        }).subscribe({
          next: res => {
            console.log('send log success', res);
          }, error: error => {
            console.log('error.message', error);
          }
        });

        if (initUrl) {
          window.location.href = initUrl;
        } else {
          window.location.reload();
        }
      } catch (ex) {
        console.error('error in GlobalErrorHandler', ex);
      }
    } else {
      console.log('error.message', error.message);
      console.log(
        'chunkFailedMessage.test(error.message)',
        chunkFailedMessage.test(error.message)
      );
    }
  }
}
