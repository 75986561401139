<div (click)="$event.stopPropagation();picker.open()" class="cursor-pointer">
  <mat-form-field appearance="fill" class="w-full">
    <mat-label>Select a date range</mat-label>
    <mat-date-range-input [rangePicker]="picker" [formGroup]="range">
      <input matStartDate #dateRangeStart formControlName="start" placeholder="Start date"
        (click)="$event.stopPropagation();picker.open()">
      <input matEndDate #dateRangeEnd formControlName="end" placeholder="End date"
        (click)="$event.stopPropagation();picker.open()" (dateChange)="dateRangeChange()">
    </mat-date-range-input>
    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
  </mat-form-field>
</div>