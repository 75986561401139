import { Observable, delay } from 'rxjs';
import { environment } from './../../../../../environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { BaseComponent } from 'src/app/common/components/base.component';
import { VisitorEventService } from './../../services/visitor.event.service';
import { Inject } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { VisitorEvent } from 'src/app/modules/shared/models/visitor.event';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { isEmptyArray } from 'src/app/common/utils/object.extensions';
import { MapMarker } from '@angular/google-maps';
import { IEventDetailsModalData } from './event.details.modal.provider';
import { CalendarQueryCommand } from 'src/app/modules/shared/models/calendar.action.command';
import { ErrorAlertService } from 'src/app/common/services/error.alert/error.alert.service';
import { SeoService } from 'src/app/common/services/seo.service/seo.service';
import { ConfigService } from 'src/app/common/services/config/config.service';
import { VisitorPlaceService } from '../../services/visitor.place.service';
import { VisitorPlace } from 'src/app/modules/shared/models/visitor.place';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { IMapMarkerWithCustomData } from 'src/app/modules/shared/models/place.marker';

export class EventDetailsBaseComponent extends BaseComponent {
  event: VisitorEvent;
  allEvents: VisitorEvent[];
  nearByPlaces$: Observable<VisitorPlace[] | null>;

  isLoading: boolean = false;
  isShowingNearbyPlacesOnMap: boolean = false;
  displayDesc: string = '';
  mapMarkers: IMapMarkerWithCustomData<VisitorPlace | VisitorEvent>[] = [];

  isOpened = false;

  constructor(
    public activatedRoute: ActivatedRoute,
    public eventService: VisitorEventService,
    public placeService: VisitorPlaceService,
    public seoService: SeoService,
    public errorAlert: ErrorAlertService,
    public sanitizer: DomSanitizer,
    public dialogRef: MatDialogRef<EventDetailsBaseComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IEventDetailsModalData
  ) {
    super({activatedRoute});
    this.loadEvent();

    this.dialogRef
      .afterOpened()
      .pipe(delay(60))
      .subscribe(() => {
        this.isOpened = true;
      });
  }

  public loadEvent() {
    if (this.data.eventDetailsId) {
      this.isLoading = true;
      this.eventService
        .getEvents(this.hubName, {
          id: this.data.eventDetailsId,
          isForUpcoming: true,
        } as CalendarQueryCommand)
        .subscribe((events) => {
          if (isEmptyArray(events)) {
            this.errorAlert.showErrorAlert(
              "Uh-oh! Looks like this event has already happened! But don't worry, we have more exciting events you can check on our calendar"
            );
            this.close();
            this.seoService.createNoIndexMeta();
            return;
          }
          this.event = events.first();
          this.allEvents = events.splice(1);
          this.processEvent();

          this.isLoading = false;
        });
    } else {
      this.event = Object.assign({}, this.data.currentEvent);
      this.allEvents = this.data.allEvents;
      this.processEvent();
    }
  }

  getDisplayCategory(place: VisitorPlace) {
    return this.placeService.getDisplayCategory(place);
  }

  public close() {
    this.dialogRef.close();
  }

  public showDescription() {
    alert(this.event.description);
  }

  public getDirection(event: VisitorEvent) {
    let address = event.address.address || event.address;
    let url = ConfigService.config.google.googleMapGetDirection.replace(
      '${address}',
      encodeURIComponent(address.toString())
    );
    window.open(url, '_blank');
  }

  public getLink(link) {
    if (link && !link.startsWith('http')) {
      return `http://${link}`;
    }
    return link;
  }

  public showLink() {
    this.copyToClip(window.location.href);
    alert(
      `The following text will be copied into your clipboard:\n\n ${window.location.href}`
    );
  }

  private copyToClip(content) {
    let aux = document.createElement('input');
    aux.setAttribute('value', content);
    document.body.appendChild(aux);
    aux.select();
    document.execCommand('copy');
    document.body.removeChild(aux);
  }

  private processEvent() {
    this.processDescription();
    this.setMap();
    this.setNameIdAndSeo();
  }

  private setNameIdAndSeo() {
    if (!this.isScriptSolution) {
      this.seoService.addNameIdToUrlForNonScriptSolution(
        this.event,
        this.hubName,
        this.dialogRef
      );
    } else {
      // for clients who use the script solution (we embed ImGoing as script into client's website)
      // change the URL by adding the ImGoing event name + Id to the params so that each place has a unique URL
      // when close the details modal, reset the URL to the original URL of the host site
      if (this.seoService.getIfNeedToAddNameIdToUrl()) {
        this.seoService.addNameIdToUrlForScriptSolution(
          this.event,
          this.dialogRef
        );
      }

      this.seoService.createAndInjectGoogleStructureDataScriptForEvent(
        this.event
      );
      this.seoService.addMetaForSEO(this.event.title, this.event.description);
    }
  }

  private processDescription() {
    if (!this.event.description) return;
    if (this.displayDesc.endsWith('read more</a>')) return;

    if (this.event.socialName?.includes('serp')) {
      if (this.event.description.endsWith('...')) {
        this.displayDesc =
          this.event.description +
          `&nbsp;<a href="${this.event.eventLink}" target="_blank">read more</a>`;
        return;
      }
    }

    this.displayDesc = this.event.description;

    // // remove html tags
    // const regex = /(<([^>]+)>)/gi;
    // this.displayDesc = this.event.description.replace(regex, '');
  }

  private setMap() {
    this.mapMarkers = [
      {
        position: {
          lat: this.event?.address?.lat,
          lng: this.event?.address?.lng,
        },
        data: this.event,
      },
    ] as IMapMarkerWithCustomData<VisitorPlace | VisitorEvent>[];
  }
}
