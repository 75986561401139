import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'ig-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ButtonComponent {
  public isLoading: boolean = false;
  public isSuccess: boolean = false;
  @Input() public icon: string;
  @Input() public disabled: boolean;
  @Input() public iconAtLeft: boolean;
  @Input() public passClickEvent: boolean;
  @Input() public isAsync: boolean;
  @Input() public textIconSpaceBetween: boolean = false;
  @Input() public cls: string = '';
  @Input() public successText: string = '';
  @Input() public color: 'primary' | 'accent' | 'cancel' | 'warn' = 'primary';
  @Input() public style: 'raised' | 'flat' = 'raised';
  @Output() public trigger = new EventEmitter<ButtonTriggerEvent>();

  constructor() {}

  public onClick($event: Event) {
    if (this.passClickEvent) {
      return;
    }

    $event.preventDefault();
    $event.stopPropagation();

    let triggerEvent: ButtonTriggerEvent = {
      complete: this.complete.bind(this),
      immediateComplete: this.immediateComplete.bind(this),
      stop: this.complete.bind(this),
      success: this.success.bind(this),
    };

    if (this.isAsync) {
      this.load();
    }

    this.trigger.emit(triggerEvent);
  }

  private load() {
    this.isLoading = true;
  }

  private complete() {
    setTimeout(() => {
      this.isLoading = false;
    }, 500);
  }

  private success() {
    setTimeout(() => {
      this.isLoading = false;
      this.isSuccess = true;
      setTimeout(() => {
        this.isSuccess = false;
      }, 5000);
    }, 500);
  }

  public immediateComplete() {
    this.isLoading = false;
  }
}

export class ButtonTriggerEvent {
  complete: () => void;
  immediateComplete: () => void;
  stop: () => void;
  success: () => void;
}
