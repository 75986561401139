import { BaseComponent } from './../../../../common/components/base.component';
import { ActivatedRoute, Router } from '@angular/router';
import { FavoriteCustomEvent } from './../../../shared/models/favorite.custom.event';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, takeUntil, delay } from 'rxjs';
import { frontendSettingsSelector } from 'src/app/modules/shared/ngrx.stores/frontend.settings/selectors';
import {
  IAppState,
  IFrontendSettings,
} from 'src/app/modules/shared/ngrx.stores/frontend.settings/states';
import { VisitorEventService } from '../../services/visitor.event.service';
import * as EventQueryCommandActions from 'src/app/modules/event-calendar/ngrx.stores/event.query.command/actions';
import { eventQueryCommandSelector } from 'src/app/modules/event-calendar/ngrx.stores/event.query.command/selectors';
import {
  getRouteUrl,
  routeConfigs,
} from 'src/app/common/constants/route.config';
import { Location } from '@angular/common';
import { VisitorService } from 'src/app/modules/shared/services/visitor.service';

@Component({
  selector: 'ig-favorite-custom-event-filter',
  templateUrl: './favorite.custom.event.filter.component.html',
  styleUrls: ['./favorite.custom.event.filter.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FavoriteCustomEventFilterComponent extends BaseComponent {
  title = 'EVENT CATEGORIES';
  frontendSettings$: Observable<IFrontendSettings | null>;
  @Input() mode: 'expand' | 'dropdown' = 'expand';
  @Output() favSelected = new EventEmitter();

  constructor(
    public activatedRoute: ActivatedRoute,
    private _router: Router,
    private _store: Store<IAppState>,
    private _eventService: VisitorEventService,
    private _location: Location,
    public visitorService: VisitorService
  ) {
    super({ activatedRoute });
    this.frontendSettings$ = this._store.pipe(
      takeUntil(this.isDestroyed),
      select(frontendSettingsSelector)
    );

    // set title and selected outline
    this._store
      .pipe(
        takeUntil(this.isDestroyed),
        delay(500),
        select(eventQueryCommandSelector)
      )
      .subscribe((cmd) => {
        this.frontendSettings$.subscribe((settings: IFrontendSettings) => {
          if (!settings) return;

          if (cmd?.favoriteEventBtnId) {
            const btn = settings.customSettings.customEvents.find(
              (btn) => btn._id === cmd.favoriteEventBtnId
            );
            btn && (this.title = btn.btnText);

            if (this.hubName == 'Savannah' || this.hubName == 'test01') {
              if (btn) {
                const currentPath = this._location.path();
                if (currentPath.includes('ig-custom-events=')) {
                  this._location.go(currentPath.split('ig-custom-events=')[0]
                    + `ig-custom-events=${this.visitorService.convertToUrlSafeString(btn.btnText)}-${btn._id}`);
                } else {
                  const connector = currentPath.includes('?') ? '&' : '?';
                  this._location.go(currentPath + `${connector}ig-custom-events=${this.visitorService.convertToUrlSafeString(btn.btnText)}-${btn._id}`);
                }
              }
            }
          }

          if (cmd?.choice) {
            this.title =
              settings.customSettings.customEditorChoice.editorChoiceForEvent;
          }
        });
      });
  }

  clear() {
    this.gotoCategory();
  }

  gotoCategory(btn?: FavoriteCustomEvent) {
    this.title = btn?.btnText || 'EVENT CATEGORIES';
    this._store.dispatch(
      EventQueryCommandActions.setFavoriteEventBtnId({
        favBtnId: btn?._id,
      })
    );

    // this.jumpToEventCalendar();
    this.favSelected.emit();
  }

  gotoEditorChoice(text: string) {
    this.title = text;
    this._store.dispatch(
      EventQueryCommandActions.setIsChoice({
        choice: true,
      })
    );

    // this.jumpToEventCalendar();
    this.favSelected.emit();
  }

  jumpToEventCalendar() {
    this._router.navigateByUrl(
      getRouteUrl(routeConfigs.visitor.eventCalendar, {
        hubName: this.hubName,
      }),
      { skipLocationChange: this.noChangeUrlWhenRedirect }
    );
  }
}
