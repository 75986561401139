import { Injectable } from '@angular/core';
import * as socketIO from 'socket.io-client';
// import { io } from "socket.io-client";
import { Socket } from 'socket.io-client';
import { ConfigService } from '../../../common/services/config/config.service';

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  public socket: Socket;

  public init() {
    console.log('init socket');
    if (!this.socket) {
      this.socket = socketIO(ConfigService.config.socketIOAddress);
      console.log('socket', this.socket);
    }
  }

  public close() {
    if (this.socket) {
      this.socket.disconnect();
      this.socket.close();
      this.socket = undefined;
    }
  }
}
