import { ActivatedRoute } from '@angular/router';
import { ConfigService } from './../../../../common/services/config/config.service';
import {
  AfterViewInit,
  Component,
  HostBinding,
  Input,
  OnInit,
  Renderer2,
} from '@angular/core';
import { BaseComponent } from 'src/app/common/components/base.component';
import { ImageService } from 'src/app/common/services/image/image.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'ig-powered-by',
  templateUrl: './powered.by.component.html',
  styleUrls: ['./powered.by.component.scss'],
})
export class PoweredByComponent extends BaseComponent implements OnInit {
  @HostBinding('class') hostClass = '';
  @Input() public style: 'iti-vertical' | 'iti-horizontal' = 'iti-vertical';
  public itiLogoUrl = '';
  public googleLogoUrl = '';

  // these clients want to hide ITI logo
  hideForClients = ['EurekaCA', 'TalbotCoMD'];

  constructor(
    public imageService: ImageService,
    public renderer: Renderer2,
    public activatedRoute: ActivatedRoute
  ) {
    super({activatedRoute});
  }

  public ngOnInit() {
    this.itiLogoUrl = this.imageService.imageSourceUrl(
      'https://iti-images.s3.amazonaws.com/imgs/iti.png'
    );
    this.googleLogoUrl = this.imageService.imageSourceUrl(
      environment.serverDomain + '/assets/imgs/google-logo.png'
    );

    if (this.hideForClients.includes(this.hubName)) {
      this.hostClass = 'max-w-0';
    }
  }
}
