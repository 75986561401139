import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'ig-image-viewer-modal',
  templateUrl: './image.viewer.modal.component.html',
  styleUrls: ['./image.viewer.modal.component.scss'],
})
export class ImageViewerModalComponent {
  constructor(
    public dialogRef: MatDialogRef<ImageViewerModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ImageViewerModalData
  ) {}

  public close() {
    this.dialogRef.close();
  }
}

export class ImageViewerModalData {
  imgs: string[];
}
