import {
  Component,
  EventEmitter,
  HostBinding,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { IDateRange } from './date.range';

@Component({
  selector: 'ig-date-range-picker',
  templateUrl: './date.range.picker.component.html',
  styleUrls: ['./date.range.picker.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DateRangePickerComponent {
  @HostBinding('class') hostClass = 'ig-date-range-picker';

  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });

  @Output() onRangeChanged: EventEmitter<IDateRange> = new EventEmitter();

  dateRangeChange() {
    if (!this.range.value.start || !this.range.value.end) return;

    this.onRangeChanged.emit({
      start: this.range.value.start,
      end: this.range.value.end,
    });
  }

  reset() {
    this.range.reset();
  }
}
